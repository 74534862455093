import { Routes } from '@angular/router';
import { AppRoutes } from '@routes/constants/appRoutes';
import { environment } from '@environments/environment';

const developmentRoutes = [
  {
    path: AppRoutes.PREVIEW,
    loadComponent: () => import('@views/preview/preview.component').then((m) => m.PreviewComponent)
  },
  {
    path: AppRoutes.FEATURE_PREVIEW,
    loadComponent: () =>
      import('@views/feature-preview/feature-preview.component').then((m) => m.FeaturePreviewComponent)
  }
];

export const routes: Routes = [
  ...(environment.production ? [] : developmentRoutes),
  {
    path: AppRoutes.WILD_CARD,
    loadComponent: () => import('@views/base/base.component').then((m) => m.BaseComponent)
  }
];
