import { Routes } from '@angular/router';
import { AppRoutes } from '@routes/constants/appRoutes';
import { environment } from '@environments/environment';

const profileInfoRoutes: Routes = [
  {
    path: AppRoutes.PROFILE_INFO,
    loadComponent: () =>
      import('@app/features/profile-page/profile-page.component').then((m) => m.ProfilePageComponent),
    children: [
      {
        path: AppRoutes.ACCOUNT,
        loadComponent: () =>
          import('@app/features/profile-information-form/profile-information-form.component').then(
            (m) => m.ProfileInformationFormComponent
          )
      },
      {
        path: AppRoutes.ORDER_HISTORY,
        loadComponent: () =>
          import('@app/features/order-history/order-history.component').then((m) => m.OrderHistoryComponent)
      },
      {
        path: '',
        redirectTo: 'account',
        pathMatch: 'full'
      }
    ]
  }
];

const developmentRoutes = [
  {
    path: AppRoutes.DEVELOPMENT,
    loadComponent: () =>
      import('@layout/header-footer-layout/header-footer-layout.component').then((m) => m.HeaderFooterLayoutComponent),
    children: [
      ...profileInfoRoutes,
      {
        path: AppRoutes.PREVIEW,
        loadComponent: () => import('@views/preview/preview.component').then((m) => m.PreviewComponent)
      },
      {
        path: AppRoutes.FEATURE_PREVIEW,
        loadComponent: () =>
          import('@views/feature-preview/feature-preview.component').then((m) => m.FeaturePreviewComponent)
      },
      {
        path: AppRoutes.LANDING_PAGE,
        loadComponent: () => import('@views/landing-page/landing-page.component').then((m) => m.LandingPageComponent)
      },
      {
        path: AppRoutes.SEARCH,
        loadComponent: () => import('@views/search-page/search-page.component').then((m) => m.SearchPageComponent)
      },
      {
        path: AppRoutes.PLP,
        loadComponent: () =>
          import('@views/product-listing-page/product-listing-page.component').then(
            (m) => m.ProductListingPageComponent
          )
      },
      {
        path: AppRoutes.PDP,
        loadComponent: () => import('@app/features/product-detail-page/pdp/pdp.component').then((m) => m.PdpComponent)
      }
    ]
  }
];

export const routes: Routes = [
  ...(environment.production ? [] : developmentRoutes),
  {
    path: AppRoutes.WILD_CARD,
    loadComponent: () => import('@views/base/base.component').then((m) => m.BaseComponent)
  }
];
