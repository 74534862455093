import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet
} from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';
import { NotificationComponent } from '@components/notification/notification.component';
import { LoaderComponent } from '@components/loader/loader.component';
import { generalConstants } from '@constants/generalConstants';
@Component({
  selector: 'aladdin-root',
  standalone: true,
  imports: [RouterOutlet, NotificationComponent, LoaderComponent],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  private routerSubscription?: Subscription;
  public isPageLoading?: boolean = false;
  public generalConstants = generalConstants;

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.routerSubscription = this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.isPageLoading = true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.isPageLoading = false;
        }
      });
    }
  }

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
  }
}
