import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NotificationClass,
  NotificationIcon,
  NotificationSidebarClass,
  NotificationTypes
} from '@app/core/enum/notification.enum';
import { INotification, NotificationService } from '@app/core/services/notification.service';

@Component({
  selector: 'aladdin-notification',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  public notifications: INotification[] = [];
  private notificationMapping = {
    [NotificationTypes.SUCCESS]: {
      bgClass: NotificationClass.SUCCESS,
      sidebarClass: NotificationSidebarClass.SUCCESS,
      icon: NotificationIcon.SUCCESS
    },
    [NotificationTypes.ERROR]: {
      bgClass: NotificationClass.ERROR,
      sidebarClass: NotificationSidebarClass.ERROR,
      icon: NotificationIcon.ERROR
    },
    [NotificationTypes.WARNING]: {
      bgClass: NotificationClass.WARNING,
      sidebarClass: NotificationSidebarClass.WARNING,
      icon: NotificationIcon.WARNING
    }
  };

  constructor(private notificationService: NotificationService) {
    this.notificationService.notifications$.subscribe((notifications) => (this.notifications = notifications));
  }

  remove(id: number) {
    this.notificationService.removeNotification(id);
  }

  getNotificationBgClass(type: NotificationTypes): string {
    return this.notificationMapping[type]?.bgClass || '';
  }

  getSidebarClass(type: NotificationTypes): string {
    return this.notificationMapping[type]?.sidebarClass || '';
  }

  getIcon(type: NotificationTypes): string {
    return this.notificationMapping[type]?.icon || '';
  }
}
