/* src/app/shared/components/notification/notification.component.scss */
.notification-container {
  position: fixed;
  top: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  z-index: 99999;
}
@media screen and (max-width: 576px) {
  .notification-container {
    top: 4rem;
    right: 2.2rem;
  }
}
.notification {
  display: flex;
  border-radius: 0.5rem;
  width: 33rem;
  min-height: 5.8rem;
  height: auto;
  max-width: calc(100vw - 4.8rem);
  position: relative;
  margin-bottom: 1rem;
  margin-left: auto;
  overflow: hidden;
  line-height: 1.5715;
  word-wrap: break-word;
  border-radius: 0.7rem;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
  animation: slideInRight 0.3s ease-in-out forwards, fadeOut 0.5s ease-in-out forwards 5s;
}
.notification__sidebar {
  width: 0.6rem;
  height: auto;
  border-radius: 0.7rem 0 0 0.7rem;
}
.notification__content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}
.notification__content-left {
  display: flex;
  flex-direction: row;
  gap: 0.7rem;
  margin-left: 1.1rem;
  word-break: break-word;
}
.notification__content-right {
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification__content-right .close-icon {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 1.5rem;
  cursor: pointer;
}
.notification__success-bg {
  background-color: #e7ffe6;
}
.notification__error-bg {
  background-color: #ffd8d8;
}
.notification__warning-bg {
  background-color: #fff4db;
}
.notification__sidebar-success {
  background-color: #1ba71b;
}
.notification__sidebar-error {
  background-color: #db5555;
}
.notification__sidebar-warning {
  background-color: #dbb255;
}
@keyframes slideInRight {
  0% {
    transform: translateX(110%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.icon {
  width: 3rem;
  height: 2.2rem;
  margin: auto 0;
  margin-left: 1.1rem;
}
.icon-success {
  background-image: url("./media/success-tick.svg");
  background-repeat: no-repeat;
}
.icon-warning {
  background-image: url("./media/warning-icon-bg.svg");
  background-repeat: no-repeat;
}
.icon-error {
  background-image: url("./media/close-icon-bg.svg");
  background-repeat: no-repeat;
}
/*# sourceMappingURL=notification.component.css.map */
