<div class="notification-container">
  @for (notification of notifications; track notification) {
    <div class="notification" [ngClass]="getNotificationBgClass(notification.type)">
      <div [ngClass]="getSidebarClass(notification.type)" class="notification__sidebar"></div>
      <span class="icon" [ngClass]="getIcon(notification.type)"></span>
      <div class="notification__content">
        <div class="notification__content-left">
          <p class="notification__content-left__message">{{ notification.message }}</p>
        </div>
      </div>
      <div class="notification__content-right">
        <img
          src="../../../../assets/icons/svg/close-icon.svg"
          class="close-icon"
          alt=""
          (click)="remove(notification.id)"
        />
      </div>
    </div>
  }
</div>
